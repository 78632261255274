  <app-sidebar></app-sidebar>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header mr-1">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12 text-center">
            <button class="btn bg-gradient-success mr-1" (click)="open(content2, null)" ><i class="fas fa-plus-circle"></i>  Novo Contrato</button>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content ml-2 mr-2">

      <!-- Default box -->
      <div class="card">
        <div class="card-header" style="border-bottom: none;">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i> Contratos </h3>
          <div class="card-tools">
            <input [(ngModel)]="searchValue" (input)="search()" style="width: 80vh;" type="text" class="form-control" placeholder="Pesquisa">
            <a href="javascript:void();"><i class="icon-magnifier"></i></a>
          </div>
        </div>
        <div class="card-body p-0">

          <div id="printTable">

            <div class="d-none d-print-inline-block p-3">
              <img src="../../all/dist/img/sky_globo.png" style="max-width: 33px; margin-right: 8px;">
              <img src="../../all/dist/img/sky_logo.png" style="max-width: 100px;">
            </div>

              <table class="table table-striped table-hover" id="myTable">
                <thead class="bg-gradient-light">
                  <tr>
                    <th class="d-print-none">Ações</th>
                    <th>Cnpj</th>
                    <th>Razão Social</th>
                    <th>Endereço</th>
                    <th>Cep</th>
                    <th>E-mail</th>
                    <th>Validade</th>
                    <th>Data Cadastro</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of filteredList  | paginate: { itemsPerPage: 10, currentPage: paginaAtual }">
                  <tr>
                    <!-- <span style="display: contents;" (click)="open(content, item, true)"> -->
                      <td>
                        <div class="col-md-12 col-sm-12 col-12">
                          <button class="btn bg-gradient-info btn-sm mb-2" (click)="open(content,item,true)" ><i class="fas fa-eye"></i></button>
                          <button class="btn bg-gradient-warning btn-sm"  (click)="edit(content2, item,true)"><i class="fas fa-cog"></i></button>
                        </div>
                      </td>
                      <td>{{item.cnpj}}</td>
                      <td>{{item.razaosocial}}</td>
                      <td>{{item.endereco}}</td>
                      <td>{{item.cep}}</td>
                      <td>{{item.email}}</td>
                      <td>{{item.validade}}</td>
                      <td>{{item.datacadastro}}</td>
                    <!-- </span> -->
                    <!-- <td class="d-print-none"></td>
                    <td class="d-print-none"><a class="text-muted"></a></td> -->
                  </tr>
                </tbody>
            </table>
            <div class="pagination-controls">
              <pagination-controls (pageChange)="paginaAtual = $event" previousLabel="Anterior" nextLabel="Próximo"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card -->
      
    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->

  <app-footer></app-footer>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->

  <!-- Modal Detalhes-->
  <ng-template #content style="height: 590px;">
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Detalhes Contrato</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="card-body p-3">
          <table class="table table-striped table-hover" id="myTable">
            <thead class="bg-gradient-light">
              <tr>
                <th>Cnpj</th>
                <th>Nome Fantasia</th>
                <th>Endereço</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dados.cnpj}}</td>
                <td>{{dados.nomefantasia}}</td>
                <td>{{dados.endereco}}</td>
              </tr>

            </tbody>
            <thead class="bg-gradient-light">
              <tr>
                <th>Cep</th>
                <th>E-mail</th>
                <th>Validade</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dados.cep}}</td>
                <td>{{dados.email}}</td>
                <td>{{dados.validade}}</td>
               
              </tr>

            </tbody>
          </table>
        </div>
        
        <div class="card-body p-3">
          <div role="group">
            <button class="btn-select" [ngClass]="{'btn-info' : tab === 'empresa'}" aria-pressed="false" style="border-radius: 10px 0 0 0">
              <div class="label-btn"   (click)="empresas()">Empresas</div>
            </button>
            <button class="btn-select" [ngClass]="{'btn-info' : tab === 'email'}" aria-pressed="false">
              <div class="label-btn" (click)="emails()">E-mail</div>
            </button>
            <button class="btn-select" [ngClass]="{'btn-info' : tab === 'tabela'}" aria-pressed="false">
              <div class="label-btn" >Tabela de Preço</div>
            </button>
            <button class="btn-select"  [ngClass]="{'btn-info' : tab === 'integrador'}" aria-pressed="false">
              <div class="label-btn" (click)="integradores()">Integrador</div>
            </button>
            <button class="btn-select" [ngClass]="{'btn-info' : tab === 'usuarios'}" aria-pressed="false" style="border-radius: 0 10px 0 0">
              <div class="label-btn " (click)="usuarios()">Usuários</div>
            </button>
            <button  class="btn-select  bg-gradient-success" (click)="open(content, null ,false,tab,'cadastro',{empresa: contentEmpresa , email:contentEmail, tabela: contentTabela , integrador : contentIntegrador, usuario : contentUsuario})" ><i class="fas fa-plus-circle mr-1"></i>Adicionar {{tab}}</button>
          </div>

         <div class="table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-striped table-hover text-center" id="myTable">
              <tbody *ngFor="let item of sublist">
                <tr   [ngStyle]="{'background-color':item.inativo === 1 ? 'darkgray' : null }" >
                  <!-- <span style="display: contents;" (click)="open(content, item,false,tab,'editar',{empresa: contentEmpresa , email:contentEmail, tabela: contentTabela , integrador : contentIntegrador, usuario : contentUsuario})"> -->
                    <td>{{item.cnpj}}</td>
                    <td>{{item.razaosocial}}</td>
                    <td>{{item.nomefantasia}}</td>
                    <td>{{item.cpf}}</td>
                    <td>{{item.usuario}}</td>
                    <td>{{item.nomecontato}}</td>
                    <td>{{item.endereco}}</td>
                    <td>{{item.cep}}</td>
                    <td>{{item.email}}</td>
                    <td>{{item.validade}}</td>  
                    <td>{{item.datacadastro}}</td>
                  <!-- </span> -->
                    <td *ngIf="item.inativo === 1"></td>  
                    <button *ngIf="item.inativo !== 1" class="btn bg-gradient-danger btn-sm m-1" (click)="deactivate(content, item,false,tab,{empresa: contentEmpresa , email:contentEmail, tabela: contentTabela , integrador : contentIntegrador, usuarios : contentUsuario})" ><i class="fas fa-power-off"></i></button>
                    <button *ngIf="item.inativo !== 1" class="btn bg-gradient-warning btn-sm m-1" (click)="edit(content, item,false,tab,{empresa: contentEmpresa , email:contentEmail, tabela: contentTabela , integrador : contentIntegrador, usuario : contentUsuario})" ><i class="fas fa-cog"></i></button>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
    </section>
  </ng-template>

  <!-- Modal Editar Contrato-->
  <ng-template #content2>
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Contrato':'Novo Cadastro'}}</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
        <div class="card-body p-3">
            <div class="row">
              <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">CNPJ</span> 
                  </div>
                  <input type="text" name="cnpj"  [(ngModel)]="contratoEdit.cnpj" id="cnpj" class="form-control" placeholder="123.456.789-10">
                </div>
              </div>
  
              <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Razao Social</span>
                  </div>
                  <input type="text" name="razaosocial"  [(ngModel)]="contratoEdit.razaosocial"  id="razaosocial" class="form-control" placeholder="12345678910">
                </div>
              </div>


              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Nome Fantasia</span>
                  </div>
                  <input type="text" name="nomefantasia" [(ngModel)]="contratoEdit.nomefantasia" id="nomefantasia" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Endereco</span>
                  </div>
                  <input type="text" name="endereco" [(ngModel)]="contratoEdit.endereco" id="endereco" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Complemento</span>
                  </div>
                  <input type="text" name="complemento"  [(ngModel)]="contratoEdit.complemento"  id="complemento" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Bairro</span>
                  </div>
                  <input type="text" name="bairro"  [(ngModel)]="contratoEdit.bairro" id="bairro" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Cep</span>
                  </div>
                  <input type="text" name="cep" id="cep"  [(ngModel)]="contratoEdit.cep" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Email</span>
                  </div>
                  <input type="text" name="email" [(ngModel)]="contratoEdit.email" id="email" class="form-control" placeholder="12345678910">
                </div>
              </div>
            </div>
            </div>
          <hr>
  
          <div class="card-footer p-2">
            <button type="submit" class="btn bg-gradient-danger float-right" (click)="close('contrato')" >Cancelar</button>
            <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfo()" >Cadastrar</button>
            <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfo()" >Salvar</button>
          </div>
  
      </div>
  
    </section>
  </ng-template>

    <!-- Modal Empresa-->
    <ng-template #contentEmpresa>
      <section class="content ml-2 mr-2 mt-2">
    
        <div class="card">
          <div class="modal-header">
            <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Empresa':'Adicionar Empresa'}}</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
            </div>
            <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    
            <div class="card-body p-3">
              <div class="row">
                <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CNPJ</span> 
                    </div>
                    <input type="text" name="cnpj"  [(ngModel)]="empresaEdit.cnpj" id="cnpj" class="form-control" placeholder="123.456.789-10">
                  </div>
                </div>
    
                <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Razao Social</span>
                    </div>
                    <input type="text" name="razaosocial"  [(ngModel)]="empresaEdit.razaosocial"  id="razaosocial" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nome Fantasia</span>
                    </div>
                    <input type="text" name="nomefantasia" [(ngModel)]="empresaEdit.nomefantasia" id="nomefantasia" class="form-control" placeholder="12345678910">
                  </div>
                </div>
              </div>
            </div>
            <hr>
    
            <div class="card-footer p-2">
              <button type="submit" class="btn bg-gradient-danger float-right" (click)="close()" >Cancelar</button>
              <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfoEmpresa()" >Cadastrar</button>
              <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfoEmpresa()" >Salvar</button>
            </div>
    
        </div>
    
      </section>
    </ng-template>

      <!-- Modal Email-->
  <ng-template #contentEmail>
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Email':'Novo Cadastro'}}</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
          <div class="card-body p-3">
            <div class="row">
              <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Email</span> 
                  </div>
                  <input type="text" name="cnpj"  [(ngModel)]="emailEdit.email" id="cnpj" class="form-control" placeholder="123.456.789-10">
                </div>
              </div>
  
              <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Nome Contato</span>
                  </div>
                  <input type="text" name="razaosocial"  [(ngModel)]="emailEdit.nomecontato"  id="razaosocial" class="form-control" placeholder="12345678910">
                </div>
              </div>


              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Cargo</span>
                  </div>
                  <input type="text" name="nomefantasia" [(ngModel)]="emailEdit.cargo" id="nomefantasia" class="form-control" placeholder="12345678910">
                </div>
              </div>
              
            </div>
          </div>

          <hr>
          <div class="card-footer p-2">
            <button type="submit" class="btn bg-gradient-danger float-right" (click)="close()" >Cancelar</button>
            <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfoEmail()" >Cadastrar</button>
            <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfoEmail()" >Salvar</button>
          </div>
  
      </div>
  
    </section>
  </ng-template>

    <!-- Modal Tabela Preço-->
    <ng-template #contentTabela>
      <section class="content ml-2 mr-2 mt-2">
    
        <div class="card">
          <div class="modal-header">
            <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Tabela':'Novo Cadastro'}}</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
            </div>
            <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
    
          <div class="card-body p-3">
              <div class="row">
                <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CNPJ</span> 
                    </div>
                    <input type="text" name="cnpj"  [(ngModel)]="contratoEdit.cnpj" id="cnpj" class="form-control" placeholder="123.456.789-10">
                  </div>
                </div>
    
                <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Razao Social</span>
                    </div>
                    <input type="text" name="razaosocial"  [(ngModel)]="contratoEdit.razaosocial"  id="razaosocial" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Nome Fantasia</span>
                    </div>
                    <input type="text" name="nomefantasia" [(ngModel)]="contratoEdit.nomefantasia" id="nomefantasia" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Endereco</span>
                    </div>
                    <input type="text" name="endereco" [(ngModel)]="contratoEdit.endereco" id="endereco" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Complemento</span>
                    </div>
                    <input type="text" name="complemento"  [(ngModel)]="contratoEdit.complemento"  id="complemento" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Bairro</span>
                    </div>
                    <input type="text" name="bairro"  [(ngModel)]="contratoEdit.bairro" id="bairro" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Cep</span>
                    </div>
                    <input type="text" name="cep" id="cep"  [(ngModel)]="contratoEdit.cep" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Email</span>
                    </div>
                    <input type="text" name="email" [(ngModel)]="contratoEdit.email" id="email" class="form-control" placeholder="12345678910">
                  </div>
                </div>
              </div>
              </div>
            <hr>
    
            <div class="card-footer p-2">
              <button type="submit" class="btn bg-gradient-danger float-right" (click)="close()" >Cancelar</button>
              <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfo()" >Cadastrar</button>
              <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfo()" >Salvar</button>
            </div>
    
        </div>
    
      </section>
    </ng-template>

      <!-- Modal Integrador-->
  <ng-template #contentIntegrador>
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Integrador':'Novo Cadastro'}}</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
  
        <div class="card-body p-3">
            <div class="row">
              <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Usuario</span> 
                  </div>
                  <input type="text" name="cnpj"  [(ngModel)]="integradorEdit.usuario" id="cnpj" class="form-control" placeholder="123.456.789-10">
                </div>
              </div>
  
              <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Senha</span>
                  </div>
                  <input type="text" name="razaosocial"  [(ngModel)]="integradorEdit.senha"  id="razaosocial" class="form-control" placeholder="12345678910">
                </div>
              </div>


              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Token</span>
                  </div>
                  <input type="text" name="nomefantasia" [(ngModel)]="integradorEdit.token" id="nomefantasia" class="form-control" placeholder="12345678910">
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-12">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">Integrador</span>
                  </div>
                  <input type="text" name="nomefantasia" [(ngModel)]="integradorEdit.integrador" id="nomefantasia" class="form-control" placeholder="12345678910">
                </div>
              </div>

             
            </div>
            </div>
          <hr>
  
          <div class="card-footer p-2">
            <button type="submit" class="btn bg-gradient-danger float-right" (click)="close()" >Cancelar</button>
            <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfoIntegrador()" >Cadastrar</button>
            <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfoIntegrador()" >Salvar</button>
          </div>
  
      </div>
  
    </section>
  </ng-template>

    <!-- Modal Usuario-->
    <ng-template #contentUsuario>
      <section class="content ml-2 mr-2 mt-2">
    
        <div class="card">
          <div class="modal-header">
            <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>{{this.editar?'Editar Usuario':'Novo Cadastro'}}</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
              <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
            </div>
            <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         
          <div class="card-body p-3">
              <div class="row">
                <!-- 1ncode: CPF do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CPF</span> 
                    </div>
                    <input type="text" name="cnpj"  [(ngModel)]="usuarioEdit.cpf" id="cnpj" class="form-control" placeholder="123.456.789-10">
                  </div>
                </div>
    
                <!-- 1ncode: CNH do Motorista - Novo Cadastro -->
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Usuario</span>
                    </div>
                    <input type="text" name="razaosocial"  [(ngModel)]="usuarioEdit.usuario"  id="razaosocial" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
  
                <div class="col-md-6 col-sm-6 col-12">
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Email</span>
                    </div>
                    <input type="text" name="nomefantasia" [(ngModel)]="usuarioEdit.email" id="nomefantasia" class="form-control" placeholder="12345678910">
                  </div>
                </div>
  
              </div>
              </div>
            <hr>
    
            <div class="card-footer p-2">
              <button type="submit" class="btn bg-gradient-danger float-right" (click)="close()" >Cancelar</button>
              <button type="submit" *ngIf="!editar" class="btn bg-gradient-success float-right mr-2" (click)="insertInfoUsuario()" >Cadastrar</button>
              <button type="submit" *ngIf="editar" class="btn bg-gradient-success float-right mr-2" (click)="updateInfoUsuario()" >Salvar</button>
            </div>
    
        </div>
    
      </section>
    </ng-template>