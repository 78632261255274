  <app-sidebar></app-sidebar>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <section class="content-header mr-1">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-12 text-center">
            <button class="btn bg-gradient-lightblue"><i class="fas fa-print"></i>  Imprimir</button>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content --> 
    <section class="content ml-2 mr-2">

      <!-- Default box -->
      <div class="card">
        <div class="card-header" style="border-bottom: none;">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i> Solicitações </h3>
          <div class="card-tools">
            <input [(ngModel)]="searchValue" (input)="search()" style="width: 80vh;" type="text" class="form-control" placeholder="Pesquisa">
            <a href="javascript:void();"><i class="icon-magnifier"></i></a>
          </div>
        </div>
        <div class="card-body p-0">

          <div id="printTable">

            <div class="d-none d-print-inline-block p-3">
              <img src="../../all/dist/img/sky_globo.png" style="max-width: 33px; margin-right: 8px;">
              <img src="../../all/dist/img/sky_logo.png" style="max-width: 100px;">
            </div>

            <table class="table table-striped table-hover text-center" id="myTable">
              <thead class="bg-gradient-light">
                <tr>
                  <th class="d-print-none">Ações</th>
                  <th>Protocolo</th>
                  <th>Data Solicitação</th>
                  <th>Data Conclusão</th>
                  <th>Expresa</th>
                  <th>Valor Mercadoria</th>
                  <th>Produto Predominante</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of filteredList | paginate: { itemsPerPage: 10, currentPage: paginaAtual }">

                <tr>
                  <td>
                    <button class="btn bg-gradient-lightblue btn-sm" (click)="open(content,item,true)"><i class="fas fa-eye"></i></button>
                    <!-- <button class="btn bg-gradient-warning btn-sm" data-toggle="modal" data-target="#editar"><i class="fas fa-cog fa-spin"></i></button> -->
                  </td>
                  <td>{{item.protocolo}}</td>
                  <td>{{item.data_solicitacao | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                  <td>{{item.data_conclusao | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                  <td>{{item.expressa}}</td>
                  <td>{{item.valor}}</td>
                  <td>{{item.produto_pred}}</td>
                </tr>

              </tbody>
            </table>
            <div class="pagination-controls">
              <pagination-controls (pageChange)="paginaAtual = $event" 
              previousLabel="Anterior"
              nextLabel="Próximo"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
      <!-- /.card -->

    </section>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <app-footer></app-footer>
  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->

  <!-- Modal Detalhes-->
  <ng-template #content>
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Detalhes Item</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="card-body p-3">
          <table class="table table-striped table-hover text-center" id="myTable">
            <thead class="bg-gradient-light">
              <tr>
                <th>Protocolo</th>
                <th>Data Solicitação</th>
                <th>Data Conclusão</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dados.protocolo}}</td>
                <td>{{dados.data_solicitacao | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                <td>{{dados.data_conclusao | date:'dd/MM/yyyy HH:mm:ss'}}</td>
              </tr>

            </tbody>
            <thead class="bg-gradient-light">
              <tr>
                <th>Expressa</th>
                <th>Valor Mercadoria</th>
                <th>Produto Predominante</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{dados.expressa}}</td>
                <td>{{dados.valor}}</td>
                <td>{{dados.produto_pred}}</td>
                
              </tr>

            </tbody>
          </table>
        </div>
        
        <div class="card-body p-3">
          <table class="table table-striped table-hover text-center" id="myTable">
            <thead>
              <th>Protocolo Item</th>
              <th>Nome Pessoa</th>
              <th>CEP Pessoa</th>
            </thead>
            <tbody >
              <tr *ngFor="let item of sublist">
                  <td>{{item.protocolo}}</td>
                  <td>{{item.pessoa_nome}}</td>
                  <td>{{item.pessoa_cep}}</td>
                  <button  class="btn bg-gradient-danger btn-sm m-1" ><i class="fas fa-power-off"></i></button>
                  <button class="btn bg-gradient-warning btn-sm m-1" (click)="openPedidoItemResp(contentPedidoItemResp, item)"><i class="fas fa-cog"></i></button>
              </tr>
            </tbody>
        </table>
      </div>
    </div>
    </section>
  </ng-template>
  
  <!-- Modal PedidoItemResp -->
  <ng-template #contentPedidoItemResp>
    <section class="content ml-2 mr-2 mt-2">
  
      <div class="card">
        <div class="modal-header">
          <h3 class="card-title"><i class="fas fa-address-card mr-3"></i>Resposta Pedido Item</h3>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="maximize"><i class="fas fa-expand"></i></button>
          </div>
          <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        
        <div class="card-body p-3">        
          <div class="row">
            <div class="col-md-12 col-sm-12 col-12">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">ID Solicitação</span>
                </div>
                <input type="text" disabled [(ngModel)]="dados.id" class="form-control">
              </div>
            </div>
          
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Data Validade</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.data_validade type="date" name="data-validade" class="form-control">
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Data Registro</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.data_registro type="date" name="data-registro" class="form-control">
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Protocolo Resposta</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.protocolo_resp type="text" name="protocolo-resposta" class="form-control" placeholder="Digite o protocolo da resp">
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Motivo Rejeição</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.MotivoRejeicao.descricao type="text" name="protocolo-resposta" class="form-control" placeholder="Digite o motivo rej">
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Liberação Pontual</span>
                </div>
                <select [(ngModel)]=pedidoItemResp.liberacao_pontual class="form-control" name="liberacao-pontual">
                  <option value="0">Sim</option>
                  <option value="1">Não</option>
                </select>
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Usuáriomm</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.usuariomm type="text" name="usuariomm" class="form-control" placeholder="Digite o usuáriomm">
              </div>
            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">Marca</span>
                </div>
                <input [(ngModel)]=pedidoItemResp.data_validade_cadastro type="date" name="data-validade-cadastro" class="form-control">
              </div>
            </div>

          </div>
        </div>
        
        <div class="card-footer p-2">
          <button *ngIf="novoPedidoItemResp" type="submit" class="btn bg-gradient-success float-right mb-3" (click)="insertPedidoItemResp()">Cadastrar Resposta</button>
          <button *ngIf="!novoPedidoItemResp" type="submit" class="btn bg-gradient-success float-right mb-3" (click)="updatePedidoItemResp()">Atualizar Resposta</button>
          <div class="card-body p-3">
            <table class="table table-striped table-hover text-center" id="myTable">
              <thead>
                <th>Data Registro</th>
                <th>Data Validade</th>
                <th>Protocolo Resp</th>
                <th>Motivo Rejeição</th>
              </thead>
              <tbody >
                <tr *ngFor="let item of pedidoItemRespList">
                    <td>{{item.data_registro | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{item.data_validade | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{item.protocolo_resp}}</td>
                    <td>{{item.MotivoRejeicao.descricao}}</td>
                    <button class="btn bg-gradient-warning btn-sm m-1" (click)="setPedidoItemResp(item)"><i class="fas fa-cog"></i></button>
                </tr>
              </tbody>
          </table>
        </div>
        </div>
      </div>
    </section>
  </ng-template>